<!--  -->
<template>
	<div class="erp">
		<div class="swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<div class="content-box">
						<div>
							<img
								class="phone-dn"
								src="@/assets/erp/banner_font.png"
								alt=""
							/>
							<img
								style="margin-top: 20%"
								class="phone-db"
								src="@/assets/h5-erp/banner_font.png"
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<van-overlay :show="show">
			<div class="commons-overlay-warp" @click.stop>
				<div class="title">
					申请试用系统
					<i class="iconfont" @click="show = false">&#xe6b3;</i>
				</div>
				<div class="label">具体申请方法</div>
				<div>
					1.咨询热线：<span style="color: #487cff"
						>0591-87865886</span
					>
				</div>
				<div>2.扫描咨询：</div>
				<div class="ewm">
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_ewm1.png" alt="" />
						</div>
						<p>企业微信客服</p>
					</div>
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_gzhewm.png" alt="" />
						</div>
						<p>关注企业公众号</p>
					</div>
				</div>
			</div>
		</van-overlay>
		<div class="painPoint">
			<div class="commons-title" :class="advantageAnimate">
				外贸生意的 <span>“痛点”</span> 和 <span>“难点”</span>
			</div>
			<div class="commons-title-label" :class="advantageAnimate">
				针对外贸生意的小伙伴普遍遇到的问题进行归类以及分析
			</div>
			<div class="phone-db" :class="advantageAnimate">
				<img
					style="width: 100%"
					src="@/assets/h5-erp/img_tongd.png"
					alt=""
				/>
			</div>
			<div class="content phone-dn" :class="advantageAnimate">
				<div class="left-card-warp">
					<div class="left-card-box">订单进度 跟踪难</div>
					<div class="left-card-box">产品冗杂 维护难</div>
					<div class="left-card-box">成本预算 统计难</div>
					<div class="left-card-box">工作待办 及时难</div>
					<div class="left-card-box">员工撬单 监管难</div>
					<div class="left-card-box">企业客户 维系难</div>
					<div class="left-card-box">别家系统 扩展难</div>
				</div>
				<div class="right-card-warp">
					<div class="right-card-box">
						<div class="label">业务员</div>
						<div class="text">
							对产品不熟悉、缺乏客户了解，以及欠缺对客户信息的收集等
						</div>
					</div>
					<div class="right-card-box">
						<div class="label">单证员</div>
						<div class="text">
							单据与货物信息描述不一致、汇票上日期不明确、伪造单证
						</div>
					</div>
					<div class="right-card-box">
						<div class="label">跟单员</div>
						<div class="text">
							跟单员主要责任：质量和交期；货物信息、货款是否正确？有无漏定？订单是否能按时加工？时间是否有问题？
						</div>
					</div>
					<div class="right-card-box">
						<div class="label">采购主管</div>
						<div class="text">
							<!-- 拿到订单找不到合适的工厂？供需信息不对称、合作双方不信任、内部人员不专业、致使对外贸易效率低 -->
							供应商信息没有一个完整的记录系统，订单凭感觉找合作厂家，采购数据零散
						</div>
					</div>
					<div class="right-card-box">
						<div
							class="text"
							style="
								text-align: center;
								margin-top: 0;
								height: 30px;
								line-height: 30px;
							"
						>
							更多...
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="zhendui">
			<div class="zhendui-content">
				<div class="commons-title" :class="programmeAnimete">
					针对<span>难点痛点</span>提供<span>一站式解决方案</span>
				</div>
				<div class="commons-title-label" :class="programmeAnimete">
					提供全面的针对性的痛点难点解决方案，构建高效信息传递平台协助企业成功
				</div>
			</div>
		</div>
		<div class="customer">
			<div class="customer-content">
				<div class="img phone-dn" :class="coreAnimate1">
					<img src="../assets/erp/img_khgl.png" alt="" />
				</div>
				<div class="text" :class="coreAnimate1">
					<div>
						<img src="../assets/erp/icon_khgl.png" alt="" />
						<h3>客户管理</h3>
					</div>
					<p>
						通过用户画像，精准识别用户，动态跟踪用户的行为轨迹，获取用户的各维度行为数据，对客户不同的行为动机进行精准归类区分
					</p>
					<img
						class="phone-db"
						src="../assets/h5-erp/img_khgl.png"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div class="black-warp">
			<div class="black-content">
				<div class="text" :class="coreAnimate2">
					<div>
						<img src="../assets/erp/icon_yxgl.png" alt="" />
						<h3>邮箱管理</h3>
					</div>
					<p>
						为了更方便了解客户是否阅读邮件，在无需客户发送邮件回执情况下，能随时查看客户何时何地读取邮件及查看时长
					</p>
					<img
						class="phone-db"
						src="../assets/h5-erp/img_yxgl.png"
						alt=""
					/>
				</div>
				<div class="img phone-dn" :class="coreAnimate2">
					<img src="../assets/erp/img_yxgl.png" alt="" />
				</div>
			</div>
		</div>
		<div class="customer">
			<div class="customer-content">
				<div class="img phone-dn" :class="coreAnimate3">
					<img src="../assets/erp/img_dzzdh.png" alt="" />
				</div>
				<div class="text" :class="coreAnimate3">
					<div>
						<img src="../assets/erp/icon_dzzdh.png" alt="" />
						<h3>单证自动化</h3>
					</div>
					<p>
						单证员平时需要花费大量时间制作单证，为了方便单证员节省制单时间，系统可一键生成报关单据，提高工作效率，避免单证信息错误
					</p>
					<img class="phone-db" src="../assets/h5-erp/img_dzzdh.png" alt="" />
				</div>
			</div>
		</div>
		<div class="black-warp">
			<div class="black-content">
				<div class="text" :class="coreAnimate4">
					<div>
						<img src="../assets/erp/icon_rwzz.png" alt="" />
						<h3>任务追踪</h3>
					</div>
					<p>
						实时在线跟踪任务进度以及时长，轻松查看订单详细情况，一览无余
					</p>
					<img class="phone-db" src="../assets/h5-erp/img_rwzz.png" alt="" />
				</div>
				<div class="img phone-dn" :class="coreAnimate4">
					<img src="../assets/erp/img_rwzz.png" alt="" />
				</div>
			</div>
		</div>
		<div class="customer">
			<div class="customer-content">
				<div class="img phone-dn" :class="coreAnimate5">
					<img src="../assets/erp/img_sjkb.png" alt="" />
				</div>
				<div class="text" :class="coreAnimate5">
					<div>
						<img src="../assets/erp/icon_sjkb.png" alt="" />
						<h3>数据看板</h3>
					</div>
					<p>
						全方位实时数据追踪，即使出门在外也可里了解业务数据，让决策更清晰；提供精准企业数字化运营，节省管理时间
					</p>
					<img class="phone-db" src="../assets/h5-erp/img_sjkb.png" alt="" />
				</div>
			</div>
		</div>
		<div class="zhendui">
			<div class="zhendui-content">
				<div class="commons-title" :class="programmeAnimete">
					同样是<span>ERP系统</span>，您为何<span>选择我们</span>
				</div>
				<div class="commons-title-label" :class="programmeAnimete">
					我们与竞品的区别，让您选的放心，用着安心
				</div>
			</div>
		</div>
		<div class="vs">
			<img class="phone-db" src="../assets/h5-erp/img_vs.png"  :class="coreAnimate6" style="width:100%" alt="">
			<div class="vs-content phone-dn" >
				<div class="left-box" :class="coreAnimate6">
					<div class="text-1">
						别人家的ERP系统
						<i class="iconfont" @click="show = false">&#xe6b9;</i>
					</div>
					<div class="text-2">
						<div>对外贸易流程单一，复杂，步骤多，周期长...</div>
					</div>
					<ul>
						<li>群发邮件被拦截，送达量低</li>
						<li>手动搜索客户，数据爬取效率低</li>
						<li>逻辑复杂，流程难懂，系统不会用</li>
						<li>价格更昂贵，体验感差</li>
					</ul>
				</div>
				<div class="right-box" :class="coreAnimate6">
					<div class="text-1">
						我们家的ERP系统
						<i class="iconfont" @click="show = false">&#xe6b8;</i>
					</div>
					<div class="text-2">
						<div>灵活多变的对外贸易形式，省去了大量的多余环节</div>
					</div>
					<ul>
						<li>
							<i class="iconfont" @click="show = false"
								>&#xe6bb;</i
							>更高效率发送邮件、提供便捷、迅速的服务
						</li>
						<li>
							<i class="iconfont" @click="show = false"
								>&#xe6bb;</i
							>系统更集成、数据更优质
						</li>
						<li>
							<i class="iconfont" @click="show = false"
								>&#xe6bb;</i
							>流程引擎支持用户根据自身需求自行配置流程，更实用
						</li>
						<li>
							<i class="iconfont" @click="show = false"
								>&#xe6bb;</i
							>操作更智能，成本更节省
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	data() {
		return {
			programmeListType: 0,
			programmeList: [
				{ name: '客户管理', id: 0, icon: '&#xe6ac;' },
				{ name: '团队管理', id: 1, icon: '&#xe6aa;' },
				{ name: '订单管理', id: 2, icon: '&#xe6b0;' },
				{ name: '流程管理', id: 3, icon: '&#xe6b1;' },
				{ name: '生产管理', id: 4, icon: '&#xe6af;' },
				{ name: '仓储管理', id: 5, icon: '&#xe6ae;' },
			],
			show: false,
			animateClass: 'animate__animated animate__fadeInUp',
			advantageAnimate: 'dn',
			programmeAnimete: 'dn',
			coreAnimate1: 'dn',
			coreAnimate2: 'dn',
			coreAnimate3: 'dn',
			coreAnimate4: 'dn',
			coreAnimate5: 'dn',
			coreAnimate6: 'dn',
		}
	},
	methods: {
		handleScroll(e) {
			if(window.innerWidth > 800){
				if (document.documentElement.scrollTop > 200) {
					this.advantageAnimate = this.animateClass
				}
				if (document.documentElement.scrollTop > 1300) {
					this.programmeAnimete = this.animateClass
				}
				if (document.documentElement.scrollTop > 1500) {
					this.coreAnimate1 = this.animateClass
				}
				if (document.documentElement.scrollTop > 2100) {
					this.coreAnimate2 = this.animateClass
				}
				if (document.documentElement.scrollTop > 2700) {
					this.coreAnimate3 = this.animateClass
				}
				if (document.documentElement.scrollTop > 3300) {
					this.coreAnimate4 = this.animateClass
				}
				if (document.documentElement.scrollTop > 3900) {
					this.coreAnimate5 = this.animateClass
				}
				if (document.documentElement.scrollTop > 4400) {
					this.coreAnimate6 = this.animateClass
				}
			}else{
				if (document.documentElement.scrollTop > 200) {
					this.advantageAnimate = this.animateClass
				}
				if (document.documentElement.scrollTop > 1100) {
					this.programmeAnimete = this.animateClass
				}
				if (document.documentElement.scrollTop > 1200) {
					this.coreAnimate1 = this.animateClass
				}
				if (document.documentElement.scrollTop > 1700) {
					this.coreAnimate2 = this.animateClass
				}
				if (document.documentElement.scrollTop > 2200) {
					this.coreAnimate3 = this.animateClass
				}
				if (document.documentElement.scrollTop > 2700) {
					this.coreAnimate4 = this.animateClass
				}
				if (document.documentElement.scrollTop > 3100) {
					this.coreAnimate5 = this.animateClass
				}
				if (document.documentElement.scrollTop > 3600) {
					this.coreAnimate6 = this.animateClass
				}
			}
			
		},
	},
	created() {
		const v = this
		window.addEventListener('scroll', this.handleScroll)
		// setTimeout(() => {
		// 	var swiper = new Swiper('.swiper-container-2', {
		// 		slidesPerView: 1.8,
		// 		spaceBetween: 30,
		// 		centeredSlides: true,
		// 		loop: true,
		// 		navigation: {
		// 			nextEl: '.swiper-button-next',
		// 			prevEl: '.swiper-button-prev',
		// 		},
		// 	})
		// }, 1500)
	},
}
</script>
<style>
.swiper-pagination-bullet {
	background: #fff;
	width: 20px;
	height: 4px;
	border-radius: 0px;
	opacity: 1;
}
.swiper-pagination-bullet-active {
	background: #487cff;
}
</style>
<style lang='less' scoped>
.erp {
	.vs {
		background: url(../assets/erp/img_vs.png) no-repeat;
		background-size: cover;
		position: relative;
		.vs-img {
			position: absolute;
			left: 50%;
			top: 50%;
			margin: -60px 0 0 -60px;
		}
		.vs-content {
			display: flex;
			margin: 0 auto;
			width: 1200px;
			.left-box {
				width: 50%;
				height: 500px;
				margin-top: 20px;
				padding: 0 60px;
				.text-2 {
					color: #fff;
					transform: skew(-15deg);
					div {
						transform: skew(15deg);
					}
				}
				li::before {
					content: '';
					display: block;
					width: 10px;
					height: 10px;
					border-radius: 5px;
					background: #fff;
					position: absolute;
					top: 21px;
					left: 0;
				}
			}
			.right-box {
				width: 50%;
				height: 500px;
				margin-top: 20px;
				padding: 0 60px;
				.text-1 {
					color: #fff;
					padding-left: 80px;
					position: relative;
					i {
						color: #fff;
					}
				}
				.text-1::before {
					content: '';
					display: block;
					width: 50px;
					height: 58px;
					background: url(../assets/erp/icon_xx.png);
					position: absolute;
					left: 0;
					top: -6px;
				}
				.text-2 {
					background: #fff;
					color: #487cff;
					transform: skew(-15deg);
					div {
						transform: skew(15deg);
					}
				}
				li {
					padding-left: 0px;
					i {
						color: #fed92e;
						margin-right: 25px;
						font-size: 20px;
					}
				}
			}
			.text-1 {
				font-size: 30px;
				margin-top: 60px;
				color: #487cff;
				font-weight: bold;
				padding-left: 40px;
				i {
					font-size: 40px;
					float: right;
					color: #487cff;
				}
			}
			.text-2 {
				height: 60px;
				line-height: 60px;
				margin-top: 50px;
				background: #487cff;
				margin-bottom: 30px;
				text-align: center;
				font-weight: bold;
				font-size: 20px;
			}
			ul {
				li {
					list-style: none;
					font-size: 18px;
					color: #fff;
					line-height: 56px;
					padding-left: 40px;
					position: relative;
				}
			}
		}
	}
	.black-warp {
		background: #f1f1f1;
		.black-content {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			height: 590px;
			justify-content: space-between;
			padding: 90px 0 100px;
			.img {
			}
			.text {
				width: 480px;
				padding-left: 90px;
				padding-top: 74px;
				h3 {
					color: #333;
					font-size: 32px;
					margin: 15px 0;
				}
				p {
					color: #666;
					font-size: 16px;
					line-height: 32px;
				}
			}
		}
	}
	.customer {
		background: #fff;
		.customer-content {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			height: 590px;
			justify-content: space-between;
			padding: 90px 0 100px;
			.img {
			}
			.text {
				width: 480px;
				padding-right: 90px;
				padding-top: 74px;
				h3 {
					color: #333;
					font-size: 32px;
					margin: 15px 0;
				}
				p {
					color: #666;
					font-size: 16px;
					line-height: 32px;
				}
			}
		}
	}
	.zhendui {
		background: url(../assets/erp/img_bl.png) no-repeat;
		background-size: cover;
		height: 326px;
		.zhendui-content {
			padding-top: 167px;
			width: 1200px;
			margin: 0 auto;
		}
	}

	.painPoint {
		height: 960px;
		width: 1200px;
		margin: 0 auto;
		.content {
			height: 582px;
			background: url(../assets/erp/img_td.png) no-repeat;
			background-size: cover;
			margin-top: 120px;
			display: flex;
			justify-content: space-between;
			padding: 20px 40px 0;
			.left-card-warp {
				width: 280px;
				.left-card-box {
					height: 60px;
					margin-bottom: 20px;
					position: relative;
					text-align: center;
					line-height: 60px;
					background: #3564f5;
					border-radius: 6px;
					color: #fff;
					font-size: 16px;
				}
				.left-card-box::before {
					content: '';
					display: block;
					width: 16px;
					height: 16px;
					border: 3px solid #fdb622;
					border-radius: 50%;
					position: absolute;
					top: 22px;
					left: 56px;
				}
			}
			.right-card-warp {
				width: 280px;
				.right-card-box {
					padding: 10px 20px;
					background: #3564f5;
					border-radius: 6px;
					color: #fff;
					font-size: 16px;
					margin-bottom: 24px;
					.label {
						padding-left: 26px;
						position: relative;
					}
					.label::before {
						position: absolute;
						content: '';
						display: block;
						width: 16px;
						height: 16px;
						border: 3px solid #ff6476;
						border-radius: 50%;
						position: absolute;
						top: 3px;
						left: 0px;
					}
					.text {
						font-size: 12px;
						margin-top: 10px;
						line-height: 18px;
					}
				}
			}
		}
	}
	.swiper {
		width: 100%;
		height: 100vh;
		min-height: 840px;
		.swiper-slide {
			overflow: hidden;
			background: #00072d;
			height: 100vh;
			min-height: 840px;
		}
		.banner-btn {
			margin: 0 auto;
			width: 180px;
			height: 60px;
			background: #487cff;
			color: #fff;
			text-align: center;
			line-height: 60px;
			margin-block: 70px;
			cursor: pointer;
		}
		.banner-btn:active {
			background: #fff;
			color: #487cff;
		}
		.content-box {
			background: url(../assets/erp/banner840.png) no-repeat;
			height: 100vh;
			min-height: 840px;
			background-size: cover;
			margin: auto;
			div {
				padding-top: 100px;
			}
			img {
				display: block;
				margin: 0 auto;
			}
		}
	}
	@media only screen and (max-width: 800px) {
		.black-warp {
			background: #f1f1f1;
			.black-content {
				width: 100%;
				margin: 0 auto;
				display: block;
				height: auto;
				justify-content: space-between;
				padding: 50px 12px;
				.img {
				}
				.text {
					width: 100%;
					padding: 0;
					div {
						display: flex;
						text-align: center;
						justify-content: center;
						align-items: center;
						img {
							width: 25px;
							height: 25px;
							margin-right: 8px;
						}
					}
					h3 {
						color: #333;
						font-size: 20px;
						margin: 15px 0;
					}
					p {
						color: #666;
						font-size: 16px;
						line-height: 32px;
					}
					.phone-db {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}
		.customer {
			background: #fff;
			.customer-content {
				width: 100%;
				margin: 0 auto;
				display: block;
				height: auto;
				justify-content: space-between;
				padding: 50px 12px;

				.img {
				}
				.text {
					width: 100%;
					padding-right: 0;
					padding-top: 0px;
					div {
						display: flex;
						text-align: center;
						justify-content: center;
						align-items: center;
						img {
							width: 25px;
							height: 25px;
							margin-right: 8px;
						}
					}
					h3 {
						color: #333;
						font-size: 20px;
						margin: 15px 0;
					}
					p {
						color: #666;
						font-size: 16px;
						line-height: 32px;
					}
					.phone-db {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}
		.zhendui {
			background: url(../assets/h5-erp/img_bl.png) no-repeat;
			background-size: cover;
			height: 135px;
			.zhendui-content {
				padding-top: 50px;
				width: 100%;
				margin: 0 auto;
			}
		}
		.swiper-slide {
			background: #fff !important;
		}
		.swiper {
			.content-box {
				background: url(../assets/h5-erp/banner.png) no-repeat;
				background-size: cover;
    			background-position: center center;
				img {
					width: 90%;
					margin: 0 5%;
				}
			}
		}
		.trial {
			height: 100px;
		}
		.case {
			.case-content {
				width: 100%;
			}
		}
		.core {
			height: auto;
			.core-content {
				width: 100%;
				img {
					width: 90%;
				}
			}
		}
		.painPoint {
			width: 100%;
			height: auto;
		}
		.advantage {
			.advantage-content {
				width: 100%;
				padding: 35px 24px;
				ul {
					li {
						width: 100%;
						display: flex;
						.img-box {
							width: 60px;
							height: 60px;
						}
						.line {
							width: 1px;
							height: 62px;
						}
					}
				}
			}
		}
	}
}
</style>

